<template>
<div>
	<CRow>
		<CCol sm="6" md="8">
        <CCard>
          <CCardHeader>
            <h4>Create Reward Catalog</h4>      
          </CCardHeader>
          <CCardBody> 
            <CRow>
                <CCol col="12" class="text-left">                   
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol> 
            </CRow>

            <CForm>
                <CInput
                    label="Name"
                    v-model="name"
                    placeholder ="Type Catalog Name"
                    horizontal
                /> 

                 <CTextarea
                    label="Description"
                    v-model="description"  
                    placeholder="Type Catalog Description"                    
                    horizontal
                    rows="2"               
                />   

                <CInput
                    label="Highlights"
                    v-model="highlights"
                    placeholder ="Type Highlights"
                    horizontal
                /> 

                <!-- <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >User Group</label>
                  </b-col>   
                  <b-col sm="9"> 
                     <b-form-select v-model="userGroupId" :options="optUserGroups"></b-form-select>
                   </b-col>                  
                </b-row><br/>  -->

                <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >User Tier</label>
                  </b-col>   
                  <b-col sm="9"> 
                      <b-form-select v-model="usrLvl" :options="optUsrLvl"></b-form-select>
                  </b-col>                  
                </b-row><br/>

                <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Point Amount</label>
                  </b-col>   
                  <b-col sm="9"> 
                       <b-form-input type="number" min=0 step="any" v-model="pointAmount"></b-form-input>
                   </b-col>                  
                </b-row><br/>  

                <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Reward Amount</label>
                  </b-col>   
                  <b-col sm="9"> 
                       <b-form-input type="number" min=0 step="any" v-model="rwdAmount"></b-form-input>
                   </b-col>                  
                </b-row><br/>

                <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Reward Category</label>
                  </b-col>   
                  <b-col sm="9"> 
                     <b-form-select v-model="rwdCat" :options="optRwdCat"></b-form-select>
                   </b-col>                  
                </b-row><br/> 

                <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Reward Type</label>
                  </b-col>   
                  <b-col sm="9"> 
                     <b-form-select v-model="rwdType" :options="optRwdType"></b-form-select>
                   </b-col>                  
                </b-row><br/>   

                <CTextarea
                    label="Term & Conditions"
                    v-model="tc"  
                    placeholder="Type Term & Conditions"                   
                    horizontal
                    rows="2"               
                />   

              <b-row>
                  <b-col sm="3"></b-col>   
                  <b-col sm="9"> 
                       <b-button-group>
                         <router-link to="/reward/catalog" tag="b-button">Cancel</router-link>
                         <b-button  v-on:click="actionRewardCatalog()" variant="primary">Save</b-button>
                       </b-button-group>    
                  </b-col>                  
              </b-row><br/>
            </CForm>   

           </CCardBody>
        </CCard>
      </CCol>

     </CRow>

</div>
</template>

<script>
 import RewardService from '@/api/RewardService.js'; 
 import TransactService from '@/api/TransactService.js';
 import AuthService from '@/api/AuthService.js'; 

  export default {    
    data() {
      return {  
          name:'',
          description:'',  
          pointAmount:0,
          rwdAmount:0, 
          rwdType:'',
          rwdCat:'',
          highlights:'',
          optRwdType:[],
          optRwdCat :[],
          // optUserGroups:[],
          // ListUserGroup:[],
          userGroupId:'', 
          usrLvl:'',
          optUsrLvl:[],
          tc:'',          
          seen:false,
          msg :'',
          color:''          
        };  
    },
    created () {   
       this.getRewardCategoryList();
       //this.getUserGroupList();
       this.getConfigList();
    },
    methods: {

      actionRewardCatalog(){ 
         
        let params = {}; var validate = [];
        params['description'] = this.description; 
        //params['highlights'] = this.ListUserGroup[this.userGroupId]; 
        params['highlights'] = this.highlights;
        params['name'] = this.name;
        params['pointAmount'] = this.pointAmount;
        params['rewardAmount'] = this.rwdAmount;
        params['rewardCategoryId'] = this.rwdCat;
        params['rewardType'] = this.rwdType;
        params['termsAndConditions'] = this.tc;
        // params['userGroupId'] = this.userGroupId; 
        params['userTier'] = this.usrLvl;

        for (var key in params) {
          if(!params[key]) { validate.push(false); } else { validate.push(true); }
        }

        if(!validate.includes(false)){   //console.log(params);
           this.createRewardCatalog(params);  
           this.resetFormCatalog();
        } else {
           this.msg   = 'Data Not Complete !'; 
           this.color = 'warning'; this.seen  = true;          
        } 
        setTimeout( () =>  this.seen=false , 5000);
      }, 

      resetFormCatalog(){

          this.name = '';
          this.description = '';
          this.userGroupId = '';
          this.pointAmount = 0;
          this.rwdAmount = 0;
          this.rwdCat = '';
          this.rwdType = '';
          this.tc = '';
      },
      
      createRewardCatalog: function(params) { 
          RewardService.createRewardCatalog(params).then(resp => {  //console.log(resp);

            if(resp.message=='Success'){
                this.msg   = 'Create Reward Catalog Successfully'; 
                this.color = 'success';  this.seen  = true;
                this.name=''; this.description=''; 
              } else {
                this.msg   = !resp.message ? "Create Reward Catalog Failed!" : resp.message; 
                this.color = 'warning'; this.seen  = true;                
              }
              setTimeout( () =>  this.seen=false , 5000); 
            
           }, error => {
            console.log('error');    
          });
      },        
      
      getUserGroupList: function() {
        TransactService.getUserGroupList().then(resp => {  //console.log(resp);     
          
            var groupSrc=[]; let ListUserGroup = {};
            var groupType = resp;
            for (var key in groupType) {
                var group = groupType[key];  
                groupSrc.push({ value: group.id , text: group.groupUniqueNumber });
                ListUserGroup[group.id] = group.groupUniqueNumber;                
            }

            this.optUserGroups = groupSrc;
            this.ListUserGroup = ListUserGroup;

        }, error => {
          this.loading = false;
        });
      },

      getRewardCategoryList: function() {
        RewardService.getRewardCategoryList().then(resp => {  //console.log(resp.length);
          
            var catSrc=[]; var temp2 = [];
            var catType = resp;
            for (var key in catType) {
                var cat = catType[key];  
                catSrc.push({ value: cat.id , text: cat.name });
            }
            this.optRwdCat = catSrc;


        }, error => {
          this.spinner = false;
        });
      },
     
      createRewardCategory: function(params) { 
          RewardService.createRewardCategory(params).then(resp => {  //console.log(resp);

            if(resp.message=='Success'){
                this.msg   = 'Create New Reward Category Successfully'; 
                this.color = 'success';  this.seen  = true;
                this.name=''; this.description=''; 
              } else {
                this.msg   = !resp.data.message ? "Create New Reward Category Failed!" : resp.data.message; 
                this.color = 'warning'; this.seen  = true;                
              }
              setTimeout( () =>  this.seen=false , 5000);  
            
           }, error => {
            console.log('error');    
          });
      },       
      
      getConfigList: function() { 
          AuthService.getConfigList().then(resp => {  console.log(resp);
              this.optRwdType = resp.rewardTypes;

              var usrTLType = resp.userTierList; var userTLSrc=[];
              for (var key in usrTLType) {
                  var usrTLName = usrTLType[key];  
                  userTLSrc.push({ value: usrTLName , text: usrTLName });
              }
              this.optUsrLvl = userTLSrc;


          }, error => {
            console.log('error');    
          });
      }, 
      
    }
  }
</script>